$color-midnight: #132438;
$color-dusk: #218094;
$color-lake: #003b70;
$color-seafoam: #74ddbc;
$color-sky: #b8f2eb;
$color-sunrise: #fff78a;
$color-smoke: #646464;
$color-puriri: #d73323;
$color-karaka: #ee813e;
$color-kawakawa: #38bb4f;
$color-kikorangi: #0038ee;
$color-white: #fff;
$color-ps-gradient-start: #f7d18c;
$color-ps-gradient-end: #fde4ba;
$ps-gradient: linear-gradient(180deg, #fde4ba 0%, #f7d18c 100%);

$color-ps-secondary: #b1b1b1;
$color-border: #dce1e5;

// buttons
$palette-blue-10: #f4f7f9;
$palette-blue-20: #ebf0f5;
$palette-blue-40: #99b4cb;
$palette-blue-80: #336996;
$palette-blue-100: #00447c;
$palette-blue-110: #002b63;

$palette-green-10: #e5f6f5;
$palette-green-20: #ccefec;
$palette-green-40: #99dfd8;
$palette-green-80: #33beb1;
$palette-green-100: #00ae9e;
$palette-green-110: #009585;

$palette-gray-10: #edf0f2;
$palette-gray-20: #dce1e5;
$palette-gray-40: #b9c4cb;
$palette-gray-80: #728998;
$palette-gray-100: #4f6b7e;
$palette-gray-110: #365265;

$palette-green-80: #33beb1;
$palette-green-100: #00ae9e;
$palette-green-110: #009585;

$palette-orange-10: #fff1e9;
$palette-orange-20: #ffe4d3;
$palette-orange-40: #ffcaa8;
$palette-orange-80: #ff9551;
$palette-orange-100: #ff7a25;
$palette-orange-110: #e6610c;

$palette-red-10: #fce7e7;
$palette-red-20: #fbd0d0;
$palette-red-40: #f7a0a0;
$palette-red-80: #ee4141;
$palette-red-100: #ea1212;
$palette-red-110: #d10000;

$palette-yellow-10: #fefae8;
$palette-yellow-20: #fef5d1;
$palette-yellow-40: #fceca4;
$palette-yellow-80: #f9d949;
$palette-yellow-100: #f8cf1b;
$palette-yellow-110: #dfb602;

$palette-black-10: #edf0f2;
$palette-black-20: #dce1e5;
$palette-black-40: #b9c4cb;
$palette-black-80: #728998;
$palette-black-100: #4f6b7e;
$palette-black-110: #365265;
$palette-black-120: #1c384b;
$palette-black-130: #031f32;
$palette-black-140: #000518;

$c-background: $palette-blue-10;
$c-border: $palette-gray-20;
$c-text-light: $palette-gray-40;
$c-text-regular: $palette-gray-80;
$c-text-default: $palette-gray-100;
$c-text-dark: $palette-gray-110;
$c-text-disabled: $palette-gray-40;

//shadows
$box-shadow-sm: 0 10px 40px rgba(0, 36, 100, 0.1);
$box-shadow-md: 0px 26px 40px rgba(0, 36, 100, 0.15);

//secondary colors
$color-dusk-10: rgba($color-dusk, 0.1);
$color-dusk-25: rgba($color-dusk, 0.25);
$color-karaka-10: rgba($color-karaka, 0.1);
$color-lake-25: rgba($color-lake, 0.25);
$color-seafoam-25: rgba($color-seafoam, 0.25);
$color-seafoam-25-darker: darken($color-seafoam, 25%);
$color-sky-25: rgba($color-sky, 0.25);
$color-smoke-10: rgba($color-smoke, 0.1);
$color-smoke-25: rgba($color-smoke, 0.25);
$color-smoke-50: rgba($color-smoke, 0.5);
$color-text-default-85: rgba($c-text-default, 0.85);
$color-text-light-85: rgba($c-text-regular, 0.85);

// Other colors
$background-color: #f4f7f9;
$text-light: #728998;
$text-dark: #365265;

$c-button-disabled-light: $palette-gray-20;
$c-button-disabled-dark: $palette-gray-40;

$c-button-default-light: $palette-blue-10;
$c-button-default-dark: $color-midnight;
$c-button-default-darker: $palette-blue-110;

$c-button-primary-light: $color-seafoam;
$c-button-primary-dark: $color-lake;
$c-button-primary-darker: $palette-green-110;

$c-button-secondary-light: $palette-red-10;
$c-button-secondary-dark: $palette-red-100;
$c-button-secondary-darker: $palette-red-110;

$c-link-button-disabled: $palette-gray-40;
$c-link-button-default: $color-white;
$c-link-button-primary: $palette-blue-80;
$c-link-button-secondary: $palette-green-80;

$c-button-focused: $color-kikorangi;

// fund colours
$color-kiwisaver-bg: $color-dusk;
$color-kiwisaver-text: $color-white;

$color-kiwisaver-plan-bg: $color-dusk;
$color-kiwisaver-plan-text: $color-white;

$color-managed-funds-bg: $color-midnight;
$color-managed-funds-text: $color-white;

$color-investment-funds-bg: $color-midnight;
$color-investment-funds-text: $color-white;

$color-premium-service-bg: $ps-gradient;
$color-premium-service-text: $color-midnight;

$color-lifesaver-bg: $color-sky;
$color-lifesaver-text: $color-midnight;

$color-investment-series-bg: $color-seafoam;
$color-investment-series-text: $color-midnight;

$color-future-plan-bg: $color-sky;
$color-future-plan-text: $color-midnight;

// Legacy colors
$color-teal-blue: #00ae9e;
$color-light-teal-blue: #ccefec;
$color-light-blue: #4c8ab1;
$color-blue-info: #3e92cc;
$color-blue: #00447c;
$color-blue-hover: #0e5692;
$color-red: #f44336;
$color-black: #333;
$color-green: #43a047;
$color-green-info: #ccefec;
$color-orange: #f0ad4e;
$color-light-grey: #f5f5f5;
$color-medium-grey: #aeaeae;
$color-grey: #858585;
$color-slate-gray: #728998;

.color {
  &-dusk {
    color: $color-dusk !important;
  }
  &-lake {
    color: $color-lake !important;
  }
  &-midnight {
    color: $color-midnight !important;
  }
  &-puriri {
    color: $color-puriri !important;
  }
  &-smoke {
    color: $color-smoke !important;
  }
  &-text-dark {
    color: $c-text-dark !important;
  }
  &-text-default {
    color: $c-text-default !important;
  }
  &-text-default-85 {
    color: $color-text-default-85 !important;
  }
  &-text-light {
    color: $color-slate-gray !important;
  }
  &-text-light-85 {
    color: $color-text-light-85 !important;
  }
  &-white {
    color: $color-white !important;
  }
}
