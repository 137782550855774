/* stylelint-disable max-nesting-depth */
@use "shared/dist/styles/variables";
@use "src/styles/mixins";

.ff-online-portal .retirement-projector {
  &__paper {
    background-color: variables.$color-white;
    border-radius: variables.$border-radius-medium;
    box-shadow: none;
    margin: 0 auto;
    padding: variables.$spacing-md;
    width: 100%;

    &:not(.notification) {
      box-shadow: variables.$box-shadow-sm;
      margin: 0 auto;
      min-height: 50vh;
      padding: 0;

      @media (max-width: variables.$screen-sm) {
        box-shadow: none;
        background-color: variables.$background-color;
      }
      @include variables.breakpoint(variables.$screen-sm) {
        padding: variables.$spacing-md;
      }
      @include variables.breakpoint(variables.$screen-md) {
        padding: variables.$spacing-lg;
      }
      @include variables.breakpoint(variables.$screen-lg) {
        padding: variables.$spacing-xl;
      }
    }
  }
}
