.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100% !important;
}

.flex,
[class^="flex-"],
[class*=" flex-"] {
  display: flex;

  &.flex-column {
    flex-direction: column !important;

    &-center {
      justify-items: center !important;
    }
  }

  &.flex-row {
    flex-direction: row !important;

    &-reverse {
      flex-direction: row-reverse !important;
    }

    &-center {
      align-items: center !important;
    }

    &-justify-content {
      &-space-between {
        justify-content: space-between;
      }
      
      &-end {
        justify-content: end !important;
      }
    }
  }
}

.align {
  &-center {
    align-items: center !important;
  }

  &-stretch {
    align-items: stretch !important;
  }

  &-justify-content {
    &-space-between {
      justify-content: space-between;
    }
    
    &-end {
      justify-content: end !important;
    }
  }

  &-self-start {
    align-self: start;
  }
}

.position-relative {
  position: relative !important;
}
