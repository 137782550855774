@use "shared/dist/styles/variables/breakpoints.scss" as b;
@use "shared/dist/styles/variables/colors.scss" as c;
@use "shared/dist/styles/variables/spacing.scss" as s;
@use "shared/dist/styles/variables/typography.scss" as t;

 .assets-list {
  display: flex;
  flex-direction: column;

  .asset-info {
    @extend .text-regular;
    color: c.$c-text-dark;
    display: inline-flex;

    .asset-label {
      flex: 0 0 auto;
      font-weight: 600;
      margin: 0 s.$spacing-md 0 0;
    }

    .asset-color {
      border-radius: 50%;
      display: inline-block;
      height: 10px;
      margin-right: s.$spacing-xs;
      width: 10px;
    }

    .asset-split {
      flex: 0 0 auto;
      text-align: right;
      margin-right: auto;

      @include b.breakpoint(b.$screen-md) {
        flex: 1 1 auto;
        margin-right: s.$spacing-2xl;
      }
    }
  }
}
