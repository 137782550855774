@use "sass:math";
@use "shared/dist/styles/variables.scss";

.ff-online-portal .custom-strategy-bar__tooltip {
  margin-bottom: 8px;
  margin-left: 3px;

  .MuiTooltip-arrow {
    margin-left: 7px !important;
  }
}

.ff-online-portal .custom-strategy-bar {
  $self: &;
  margin-bottom: variables.$spacing-lg;

  &__slider-btns {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: variables.$spacing-md;
    justify-content: space-between;

    .custom-strategy-bar__slider {
      width: 100%;
    }
  }

  &__ratio {
    text-align: right;
  }

  &__btn {
    $size: 30px;
    align-items: center;
    border: none;
    border-radius: 50%;
    display: flex;
    flex: 0 0 auto;
    height: $size;
    justify-content: center;
    width: $size;
    transition: background-color 0.3s ease;

    .custom-icon path {
      transition: stroke 0.3s ease;
    }

    &:disabled {
      background-color: variables.$color-smoke-25;
      pointer-events: none;

      .custom-icon path {
        stroke: variables.$color-smoke-50;
      }
    }

    &:not(:disabled) {
      background-color: variables.$color-sky;
      cursor: pointer;

      .custom-icon path {
        stroke: variables.$color-lake;
      }
    }
  }
}
