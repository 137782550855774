@use "shared/dist/styles/variables" as v;

.currency {
  align-items: center;
  display: inline-flex;
  flex-direction: row;

  &-black {
    color: v.$color-midnight;
  }
  &-red {
    color: v.$color-red;
  }

  &-positive {
    color: v.$color-kawakawa;
    stroke: v.$color-kawakawa;
  }

  &-negative {
    color: v.$color-puriri;
    stroke: v.$color-puriri;
  }

  &__trend-icon {
    font-size: v.$font-size-xs;
    margin-left: 0.5rem;

    path {
      fill: none;
    }
  }

  &__total {
    display: flex;
    flex-direction: row;
  }

  &--lg {
    font-size: v.$font-size-xl;
    font-weight: 300;
  
    .currency__dollar-sign {
      font-size: v.$font-size-md;
      margin-top: 5px;
    }
  }
}
