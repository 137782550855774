@use "../../styles/mixins";
@import "shared/dist/styles/variables";

.ff-online-portal .reports-container {
  background-color: $background-color;

  .MuiPaper-root {
    position: relative;
  }
  
  .loading-spinner {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .MuiPaper-root {
    margin: 0 auto;
    padding: 1rem;
    width: 100%;

    &:not(.notification) {
      box-shadow: $box-shadow-sm;
      margin: 0 auto;
      min-height: 50vh;
      padding: 0;

      @media (max-width: $screen-sm) {
        box-shadow: none;
        background-color: $background-color;
      }
      @media (min-width: $screen-sm) {
        padding: 1rem;
      }
      @media (min-width: $screen-md) {
        padding: 1.5rem;
      }
      @media (min-width: $screen-lg) {
        padding: 2rem;
      }
    }
  }

  .MuiTableHead-root .MuiTableCell-root {
    &:first-child {
      cursor: pointer;

      & .date-cell {
        display: flex;
      }
    }

    .MuiSvgIcon-root {
      margin-left: $spacing-lg;
      width: 1.25rem;
    }
  }

  .MuiTableBody-root .MuiTableCell-root {
    &:last-child {
      width: 1%;
    }

    @media (max-width: $screen-sm) {
      font-size: 0.75em;
    }
  }

  .MuiTableCell-root {
    max-width: 7rem;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    text-overflow: ellipsis;

    .MuiButton-text {
      font-size: 0;
      min-width: 1px;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    @media (min-width: 1100px) {
      max-width: inherit;

      .MuiButton-text {
        font-size: 0.875em;
      }
    }
  }

  .table-content {
    overflow-x: auto;
    padding-bottom: 1rem;

    &-wrapper {
      overflow: hidden;
    }
  }

  .MuiTypography-h5 {
    color: $color-midnight;
    margin-bottom: 1.5rem;
  }
}