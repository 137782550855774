@use "shared/dist/styles/variables/breakpoints.scss" as b;
@use "shared/dist/styles/variables/colors.scss" as c;
@use "shared/dist/styles/variables/spacing.scss" as s;
@use "shared/dist/styles/variables/typography.scss";

.review-your-strategy-change {
  > p,
  > span {
    color: c.$c-text-dark;
  }

  &.client-details {
    display: flex;
    flex-wrap: wrap;

    > div {
      > p {
        margin: 0;
        &:nth-child(even) {
          @extend .text-bold;
          color: c.$color-midnight;
        }
      }
      flex: 0 1 100%;
      margin-bottom: s.$spacing-sm;
      @include b.breakpoint(b.$screen-md) {
        flex: 0 1 33%;
      }
    }
  }

  .pds-disclaimer {
    background: c.$background-color;
    border-radius: s.$border-radius-small;
    color: c.$c-text-dark;
    display: inline-block;
    margin-bottom: s.$spacing-sm;
    padding: s.$spacing-sm s.$spacing-sm 0 s.$spacing-sm;
  }

  li {
    margin-bottom: s.$spacing-xs;
  }

  .allocation-row {
    display: flex;
    margin: s.$spacing-xs 0 s.$spacing-xs;

    :first-child {
      flex: 0 1 50%;
      @include b.breakpoint(b.$screen-md) {
        flex: 0 1 20%;
      }
    }
  }
}

.modal__footer-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}
