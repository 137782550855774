@use "sass:math";
@use "shared/dist/styles/variables.scss" as v;

$c-slider-rail: v.$color-smoke-25;
$c-slider-rail-active: v.$color-dusk;
$c-slider-track: v.$color-dusk;
$c-slider-thumb: v.$color-dusk;
$current-marker-width: 4px;

$rail-height: 4px;
$thumb-diameter-default: 18px;
$thumb-diameter-big: 23px;

.slider-v2__tooltip {
  font-size: v.$font-size-xs;

  > .MuiTooltip-tooltip {
    background-color: v.$color-dusk;
    padding: 7px v.$spacing-xs;
  }
  .MuiTooltip-arrow {
    color: v.$color-dusk;
  }
}

.slider-v2 {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: $thumb-diameter-big;
  position: relative;
  margin: 0 9px;
  width: 60%;

  &__rail,
  &__track {
    border-radius: math.div($rail-height, 2);
    height: $rail-height;
  }

  &__rail {
    background: $c-slider-rail;
    box-sizing: border-box;
    width: 100%;
    z-index: 0;

    &--active {
      background: $c-slider-rail-active;
    }
  }

  &__track {
    background: $c-slider-track;
    left: 0;
    position: absolute;
    top: math.div(($thumb-diameter-big - $rail-height), 2);
    z-index: 2;
  }

  &__thumb {
    background: v.$color-white;
    border: 2px solid $c-slider-thumb;
    border-radius: 50%;
    height: $thumb-diameter-default;
    width: $thumb-diameter-default;
    margin-left: math.div($current-marker-width, 2);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    transition-property: box-shadow, background, height, width;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    z-index: 3;

    &--active,
    &:hover {
      background: $c-slider-thumb;
      box-shadow: 0 0 0 2px $c-slider-thumb, 0 0 0 5px rgba($c-slider-thumb, 0.15);
      cursor: pointer;
      height: $thumb-diameter-big;
      width: $thumb-diameter-big;
    }
  }

  &__current {
    background-color: v.$color-border;
    border-radius: v.$border-radius-large;
    height: 100%;
    width: $current-marker-width;
    position: absolute;
    left: 0;
    z-index: 1;
    transition-property: background, width, z-index;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    &:hover {
      background: v.$color-dusk;
      width: 6px;
      z-index: 5;
    }
  }
}
