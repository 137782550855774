@import 'shared/dist/styles/variables.scss';
@import 'shared/dist/styles/variables/breakpoints.scss';
@import 'src/styles/mixins.scss';

$accounts-header-height-sm: 70px;
$accounts-header-height-md: 80px;

.dashboard-theme-container .accounts-header {
  @extend .section-padding;
  box-shadow: $box-shadow-sm;
  background-color: $color-white;
  flex-direction: row;
  height: $accounts-header-height-sm;
  overflow: hidden;
  top: 0;
  transition: top .2s ease-out, height .2s ease-out;

  @include breakpoint($screen-md) {
    height: $accounts-header-height-md;
  }

  .logo-link,
  .profile-quick-actions-component,
  .menu-toggle {
    flex: 0 0 auto;
  }

  .logo-link {
    @include breakpoint($screen-lg) {
      display: none;
    }
  }

  .MuiTabs-root {
    flex: 1 1 0;
  }

  > .MuiToolbar-root {
    flex: 1 1 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $content-width-narrow;
    width: 100%;
  }

  .profile-quick-actions-component {
    padding-left: 1rem;
    margin-left: auto;
  }
  
  .menu-toggle {
    display: none;
    margin-left: $spacing-lg;
    min-width: unset;
    padding: 0;

    @include breakpoint($screen-md) {
      display: block;
    }

    @include breakpoint($screen-lg) {
      display: none;
    }

    .menu-icon {
      color: $color-dusk;
    }
  }
}