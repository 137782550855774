@use "shared/dist/styles/variables/funds.scss";
@import "./../../styles/mixins.scss";
@import "shared/dist/styles/variables";
@import "shared/dist/styles/variables/breakpoints.scss";
@import "shared/dist/styles/variables/typography.scss";

$border-radius: 10px;
.dashboard-theme-container .account-details-header {
  align-items: center;
  background: $color-dusk-10;
  border-radius: $border-radius;
  color: $color-white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 15px;
  padding-top: 15px;
  white-space: nowrap;
  width: 100%;

  @include breakpoint($screen-md) {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .mobile {
    @include breakpoint($screen-md) {
      display: none;
    }
  }

  .desktop {
    @include mobile {
      display: none;
    }
  }

  .MuiTypography-body1 {
    @extend .text-small;
  }

  &--default.account-details-header {
    background-color: $color-lake;
  }

  &--kiwisaver,
  &--fisher-funds-two {
    &.account-details-header {
      @include funds.kiwisaver-colors;

      .account-details-header__fund {
        background-color: rgba($color-kiwisaver-text, 0.15);
      }
    }
  }

  &--kiwisaver-plan.account-details-header {
    @include funds.kiwisaver-plan-colors;

    .account-details-header__fund {
      background-color: rgba($color-kiwisaver-plan-text, 0.15);
    }
  }

  &--managed-funds.account-details-header {
    @include funds.managed-funds-colors;

    .account-details-header__fund {
      background-color: rgba($color-managed-funds-text, 0.15);
    }
  }

  &--investment-funds.account-details-header {
    @include funds.investment-funds-colors;

    .account-details-header__fund {
      background-color: rgba($color-investment-funds-text, 0.15);
    }
  }

  &--premium-service.account-details-header {
    @include funds.premium-service-colors;

    .account-details-header__fund {
      background-color: rgba($color-premium-service-text, 0.15);

      .switch-icon {
        fill: $color-premium-service-text;
      }
    }
  }

  &--lifesaver.account-details-header {
    @include funds.lifesaver-colors;

    .account-details-header__fund {
      background-color: rgba($color-lifesaver-text, 0.15);
    }
  }

  &--investment-series.account-details-header {
    @include funds.investment-series-colors;

    .account-details-header__fund {
      background-color: rgba($color-investment-series-text, 0.15);
    }
  }

  &--futureplan.account-details-header {
    @include funds.futureplan-colors;

    .account-details-header__fund {
      background-color: rgba($color-future-plan-text, 0.15);
    }
  }

  &__details {
    min-width: 0;
  }

  &__name,
  &__fund {
    color: inherit;
    line-height: 1;
  }

  &__name {
    display: flex;

    p {
      color: inherit;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: $spacing-2xs;
      margin-top: $spacing-xs;
      white-space: nowrap;
    }

    button {
      margin-top: 5px;
    }
  }

  &__fund {
    align-items: center;
    border-radius: 5px;
    display: none;
    flex-direction: row;
    font-size: 14px;
    height: 30px;
    margin-left: 30px;
    padding: 0 $spacing-xs;

    @include breakpoint($screen-md) {
      display: flex;
    }

    &,
    &:hover {
      background-color: rgba($color-white, 0.15);
      opacity: 1;
    }

    &:not(:hover) {
      .switch-icon {
        margin-left: 0;
        width: 0;
      }
    }

    .switch-icon {
      font-size: 19px;
      margin-left: $spacing-xs;
      transition:
        margin 200ms,
        width 200ms;
    }
  }

  &__type {
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }

  .action-buttons {
    margin-left: $spacing-md;

    .button {
      height: 40px;
      min-width: unset;
      width: 40px;

      @media (max-width: #{$screen-md - 1}) {
        padding: 0;

        .button__icon-left {
          margin: 0;
        }

        .button__text {
          display: none;
        }
      }

      @include breakpoint($screen-md) {
        height: 35px;
        width: auto;
      }

      .MuiSvgIcon-root {
        font-size: 28px;

        @include breakpoint($screen-md) {
          font-size: 25px;
        }
      }
    }
  }

  &__related-account {
    @extend .text-small;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: $spacing-2xs;

    > .MuiSvgIcon-root {
      margin-right: $spacing-xs;
    }
  }
}

.account-options-menu {
  li {
    > .MuiLink-root,
    > a {
      width: 100%;
    }
  }

  .MuiPopover-paper {
    box-shadow: $box-shadow-sm;
    margin-top: 2.75rem;
  }
  &:not(.deposit-mode) {
    .MuiPopover-paper {
      @media (min-width: 600px) {
        margin-left: -1rem;
        width: auto;
      }
      @media (min-width: 1070px) {
        margin-left: -2.95rem;
      }
      @media (min-width: 1610px) {
        margin-left: -4.75rem;
      }
    }
  }
  &.has-kiwisaver {
    .MuiPopover-paper {
      @media (min-width: 1610px) {
        margin-left: -7.85rem;
      }
    }
  }
  .MuiMenuItem-root {
    @media (min-width: 321px) {
      white-space: nowrap;
    }
  }

  .MuiLink-root {
    width: 100%;

    &:hover {
      color: $color-blue;
      opacity: 1;
    }
  }
}
