@use '/src/styles/pretty-card.scss';
@use "../../styles/mixins";
@import "shared/dist/styles/variables";

.investment-content-layout {
  .grid-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > * {
      flex: 1 1 100%;
      margin-bottom: 20px;
    }

    @include breakpoint($screen-xl) {
      flex-direction: row;
      flex-wrap: wrap;
      > * {
        flex-grow: 0;
        flex-basis: calc(50% - 10px);
      }
    }
  }

  .investment-tool-card {
    @extend .pretty-card;
    box-shadow: $box-shadow-sm;
    background-color: $color-white;
  }

  .MuiTypography-h5 {
    margin-bottom: 1.5rem;
    color: $color-midnight;
  }

  .investment-tool-button {
    display: inline-flex;
  }
}