@use "shared/dist/styles/variables.scss" as v;
.switch-premium {
  .notification {
    margin-bottom: v.$spacing-md;
  }

  .download-form:hover {
    .icon_download {
      path {
        stroke: v.$color-white;
      }
    }
  }

  &-form {
    display: flex;
    flex-wrap: wrap;
    gap: v.$spacing-sm;
    justify-content: space-between;

    .MuiOutlinedInput-input {
      padding: v.$spacing-button-sm v.$spacing-button-md;
    }

    > div {
      h6 {
        @extend .text-small;
        @extend .text-bold;
        color: v.$c-text-dark;
      }
      flex: 0 1 calc(50% - v.$spacing-sm);
    }
  }
}
