@use "shared/dist/styles/variables/breakpoints.scss" as b;
@import 'shared/dist/styles/variables';

$switch-progress-breakpoint: 700px;

.switch-modal {
  &.large .MuiDialog-container {
    max-width: 1185px;
  }

  .notification {
    margin-bottom: 1rem;
  }

  .switch-strategy-option {
    .form-radio {
      flex: 0 0 auto;
      margin-right: 5px;
    }

    .option__radio-label {
      display: block;
      margin-top: 9px;

      @include b.breakpoint(b.$screen-md) {
        margin-top: 7px;
      }
    }
  }

  strong {
    @extend .text-regular;
    display: block;
    margin-bottom: $spacing-2xs;
  }
  .strategy-option {
    margin-bottom: $spacing-md;
  }

  .MuiOutlinedInput-root.Mui-disabled {
    background-color: #ddd;
  }
  .MuiTextField-root {
    width: 100%;
  }
}

.switch-notifications-modal {
  .MuiDialog-container {
    @media (min-width: 769px) {
      max-height: 25rem;
    }
  }

  p {
    color: $color-blue;
    &.title {
      font-size: 18px;
    }
  }
}

.ipq-modal {
  .modal__footer-buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
  }
}