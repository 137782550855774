@use 'shared/dist/styles/variables.scss' as v;

.add-funds {
  &-details__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    > * {
      flex: 1 1 100%;
      max-width: 320px;
    }
  }
}
