@use "shared/dist/styles/variables.scss";
.ipq {
  &.large .MuiDialog-container {
    max-width: 1185px;
  }

  &__modal {
    &-header {
      color: variables.$color-midnight !important;
      font-weight: 400;
    }

    &.progress__bar {
      bottom: 0;
      background-color: rgba(variables.$color-smoke, 0.25);
      height: 6px;
      left: 0;
      overflow: hidden;
      position: absolute;
      width: 100%;

      :first-child {
        background: variables.$color-dusk;
        height: 100%;
        transition: width 200ms ease-in;
      }
    }
  }

  &__paragraph {
    @extend .text-regular;
    color: variables.$c-text-dark;
    width: 100%;
  }

  &__personal-details-form {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &__input {
      margin-top: 0.8rem;
      width: 100%;

      @include variables.breakpoint(variables.$screen-md) {
        width: 48%;
      }
    }

    &__timeframe {
      .input-select__menu-list {
        max-height: 150px;
      }
    }
  }

  &__option {
    > * {
      display: inline;
      vertical-align: middle;
    }
  }

  &__links {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: variables.$spacing-lg;

    @include variables.breakpoint(variables.$screen-md) {
      flex-direction: row;
      justify-content: center;
    }

    > * {
      margin-top: variables.$spacing-md;

      @include variables.breakpoint(variables.$screen-md) {
        margin-top: 0;

        &:not(:first-child) {
          margin-left: variables.$spacing-xl;
        }
      }
    }
  }

  &__form {
    display: flex;
    flex: 2;
    flex-direction: column;

    &__notice {
      display: flex;
      flex-wrap: wrap;
      background: variables.$background-color;
      align-self: flex-end;
      align-items: baseline;
    }

    &__buttons {
      align-items: baseline;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;

      button {
        .button__text {
          font-size: 15px;
        }

        &:first-child {
          margin-bottom: variables.$spacing-md;
        }

        .link-button__icon--left {
          margin-left: 0;
        }

        > span {
          font-size: variables.$font-size-md;
        }
      }
    }

    .question__list {
      color: variables.$c-text-dark;
      line-height: 1.467;
      list-style: auto;
      margin-top: 0;
      padding: 0 1rem;

      &-error {
        font-size: variables.$font-size-2xs;
        color: variables.$color-puriri;
      }
      .question__label {
        font-weight: 400;
        padding-bottom: 1rem;
      }

      li {
        &::marker {
          color: variables.$color-midnight;
          font-size: variables.$font-size-sm;
          font-weight: 400;
          @include variables.breakpoint(variables.$screen-md) {
            font-weight: 600;
          }
        }

        .input-radio-menu__item {
          label {
            margin-bottom: variables.$spacing-xs;
          }
        }
      }

      li::marker,
      .question__description {
        display: inline-flex;
      }

      &__notice {
        align-self: flex-end;
        background-color: variables.$c-background;
        border-radius: variables.$border-radius-small;
        color: variables.$c-text-default;
        font-size: variables.$font-size-sm;
        margin-left: auto;
        padding: variables.$spacing-xs variables.$spacing-sm;

        > .button {
          color: inherit !important;
          font-size: inherit !important;
          text-decoration: underline;
        }
      }
    }

    .modal {
      &__content-container {
        background: variables.$color-white;
        border-radius: variables.$border-radius-medium !important;
      }
    }
  }
}
