@use "shared/dist/styles/variables.scss" as v;

.mf-withdraw-form {
  label {
    font-weight: 600;
  }

  .currency__total {
    display: inline-flex;
  }

  .input-group {
    align-items: stretch;
    background-color: v.$background-color;
    border: 1px solid v.$color-seafoam;
    border-radius: v.$border-radius-large;
    color: v.$color-midnight;
    display: flex;
    flex-direction: row;
    padding: 0;

    &--disabled {
      border-color: v.$color-border;
      color: v.$color-smoke;
    }

    > *,
    .partial-withdraw-amount input {
      background: transparent;
      border: none;
    }

    .partial-withdraw-amount {
      flex: 1 1 auto;
    }

    .input-amount__wrapper {
      border: none;
      background-color: transparent;
      height: 100%;
    }
  
    .switch-funds-selector {
      flex: 0 0 auto;
      font-weight: 400;
      padding: 1px;
    }
  }

  .fund-selector {
    background-color: v.$background-color;
    border: 1px solid v.$color-seafoam;
    border-top: none;
    border-bottom-right-radius: v.$border-radius-medium;
    border-bottom-left-radius: v.$border-radius-medium;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 0 v.$spacing-md;
    padding: v.$spacing-md;
  }
}