@use "shared/dist/styles/variables.scss" as v;

.premium-service-contact-form {
  textarea {
    background-color: v.$background-color;
    border-radius: v.$border-radius-medium;
    height: 120px;
    resize: none;
  }
}
