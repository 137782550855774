@use "shared/dist/styles/variables.scss" as v;

.product-modal {
  .MuiPaper-root {
    min-height: 300px;
  }

  .MuiPaper-root,
  .modal-background {
    background: v.$c-background;
  }

  .MuiDialog-container > .MuiDialog-paper {
    position: relative;
  }

  .header {
    h4,
    .modal-subtitle {
      color: inherit;
    }
  }

  .modal-background {
    display: block;
    height: 300px;

    &:after {
      background-repeat: no-repeat;
      border-bottom-left-radius: 70px;
      border-bottom-right-radius: 70px;
      content: "";
      display: block;
      height: 100%;
    }
  }

  &--kiwisaver,
  &--kiwisaver-plan,
  &--fisher-funds-two {
    .modal-body {
      color: v.$color-kiwisaver-text;
    }
    .modal-background:after {
      background: v.$color-kiwisaver-bg;
    }
  }

  &--managed-funds {
    .modal-body {
      color: v.$color-managed-funds-text;
    }
    .modal-background:after {
      background: v.$color-managed-funds-bg;
    }

    .close-button .MuiSvgIcon-root {
      fill: v.$color-white;
    }
  }

  &--premium-service {
    .modal-body {
      color: v.$color-premium-service-text;
    }
    .modal-background:after {
      background: v.$color-premium-service-bg;
    }
  }
}