@use "shared/dist/styles/variables.scss" as v;

.managed-funds-withdraw-modal { 
  .input-amount--dollar-sign {
    &.input-amount__wrapper--error {
      background-color: transparent;
    }

    &:not(.input-amount__wrapper--error) {
      border-color: v.$color-seafoam;
    }

    .input-amount__wrapper {
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }

    .input-amount__label {
      background-color: rgba(v.$color-midnight, 0.05);
    }

    .input-amount__element {
      background-color: v.$color-white;
    }
  }
}