@use "/src/styles/dashboard-theme.scss" as dashboard;
@use "shared/dist/styles/variables" as *;
@import 'src/styles/mixins.scss';

.dual-info-modal {
  .dialog-desktop {
    display: none;
    @include breakpoint($screen-lg) {
      display: block;
    }
    .MuiDialogContent-root {
      display: grid;
      grid-template-columns: 1fr 2px 1fr;
      grid-gap: 0 1.5rem;
    }
    .header {
      margin-bottom: 0;
      padding-bottom: $spacing-md;
      padding-top: $spacing-md;
      position: relative;
      gap: 3rem;

      > h4 {
        flex: 1 1 0;
      }
    }
    .close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
    .investment-summary-info {
      min-height: 110px;
    }
    .assets-list {
      min-height: 475px;
    }
  }
  .dialog-mobile {
    @include breakpoint($screen-lg) {
      display: none;
    }
    .selected-tab-heading {
      .tab-button {
        color: $color-midnight !important;
        text-decoration: none;
      }
      hr {
        background-color: $color-dusk;
        border: 1px solid $color-dusk;
        border-radius: 2px;
        height: 4px;
        margin: 0 1rem 0 1rem;
      }
    }
    .unselected-tab-heading {
      .tab-button {
        color: $text-light !important;
        text-decoration: none;
      }
      hr {
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 2px;
        height: 4px;
        margin: 0 1rem 0 1rem;
      }
    }
    .header {
      text-align: center;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-top: $spacing-md;
      .button__text {
        font-weight: 600;
      }
    }
    .tab-button {
      padding: 0 0 1rem 0;
      margin: 0 1rem 0 1rem;

      > span {
        font-size: $font-size-md;
      }
    }
  }
  .MuiDialogContent-root {
    width: 100%;
    padding-top: $spacing-lg;
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
  .MuiPaper-root {
    padding-top: 0;
    padding-bottom: $spacing-md;
    margin: 0;

    @include breakpoint($screen-lg) {
      padding-bottom: $spacing-lg;
    }
  }
  .assets-list {
    flex-direction: column;
    justify-content: flex-start;
    > * {
      flex: 0 1 100%;
    }
  }
}
